import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";
import {
  encryptKeywords,
  decryptContent,
  encryptContent,
  decryptSymmetricKey,
} from "../utils/cryptoHelpers";

const preSaveMod = async (data, note) => {
  let key = note?.symmetricKey;
  if (!data || !note) {
    return data;
  }
  if (!key) {
    let encryptedSymmetricKey = note?.encryptedSymmetricKey;
    key = await decryptSymmetricKey(encryptedSymmetricKey);
  }
  const model = { ...data };
  if (model.keywords?.trim() > "") {
    const { encryptedContent, iv } = await encryptContent(
      model.keywords.trim(),
      key
    );
    let tokens = model.keywords?.trim()?.toLowerCase()?.split(",");

    model.keywords = encryptedContent;
    model.keywordTokens = await encryptKeywords(tokens);
    model.keywordIv = iv;
  }
  return model;
};
export const postLoadMod = async (data, note, key) => {
  let symmetricKey = key || note.symmetricKey;
  if (!data || !note) {
    return data;
  }
  const model = { ...data };
  if (!symmetricKey) {
    let encryptedSymmetricKey = note?.encryptedSymmetricKey;
    symmetricKey = await decryptSymmetricKey(encryptedSymmetricKey);
  }
  if (model.keywords && model.keywordIv) {
    model.keywords = await decryptContent(
      model.keywords,
      model.keywordIv,
      symmetricKey
    );
  }

  return model;
};

const setNote = (noteId) => {
  let note = store.state.notes?.find((note) => note.id === noteId);
  if (store.state.tokenNote && noteId && store.state.tokenNote?.id === noteId) {
    note = store.state.tokenNote;
  }
  return note;
};

export function useFileStore() {
  const isLoading = ref(false);
  const files = computed(() => store.state.files);

  async function fetchFiles(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/files", { params: query });
      store.mutations.setState("files", response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function updateFile(id, fileData, noteId) {
    try {
      let note = setNote(noteId);
      const encrypted = await preSaveMod(fileData, note);
      const response = await api.put(`/api/files/${id}`, encrypted);
      note = setNote(noteId);
      const decrypted = await postLoadMod(response.data, note);
      store.mutations.updateModel("files", id, decrypted);
      return decrypted;
    } catch (error) {
      console.error("Error updating file:", error);
      throw error;
    }
  }

  async function getFileEditUrl(fileId) {
    try {
      const response = await api.get(`/api/files/${fileId}/edit`);
      // store.mutations.addModel("files", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching file by id:", error);
      throw error;
    }
  }

  async function fetchFileById(fileId) {
    try {
      const response = await api.get(`/api/files/${fileId}`);
      const decrypted = (await postLoadMod([response.data]))[0];
      store.mutations.addModel("files", decrypted);
      return response.data;
    } catch (error) {
      console.error("Error fetching file by id:", error);
      throw error;
    }
  }

  async function searchFiles(query) {
    try {
      const response = await api.get("/api/files/search", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching files:", error);
      throw error;
    }
  }

  async function createNoteFile(fileData, noteId) {
    try {
      let note = setNote(noteId);
      const response = await api.post(`/api/notes/${noteId}/files`, {
        files: fileData,
      });
      if (response.data[0]) {
        const decrypted = await postLoadMod(response.data[0], note);
        note = setNote(noteId);
        if (note) {
          if (!note.isTokenNote) {
            store.mutations.updateModel("notes", noteId, {
              ...note,
              files: [...note.files, decrypted],
            });
          } else if (note.isTokenNote) {
            store.mutations.setState("tokenNote", {
              ...note,
              files: [...note.files, decrypted],
            });
          }
        }
        return decrypted;
      }
    } catch (error) {
      console.error("Error creating file:", error);
      throw error;
    }
  }

  async function deleteNoteFile(id, noteId) {
    try {
      await api.delete(`/api/notes/${noteId}/files/${id}`);
      let note = setNote(noteId);

      if (note) {
        if (!note.isTokenNote) {
          console.log("set mutation");
          store.mutations.updateModel("notes", noteId, {
            ...note,
            files: [...note.files.filter((f) => f.id !== id)],
          });
        } else if (note.isTokenNote) {
          store.mutations.setState("tokenNote", {
            ...note,
            files: [...note.files.filter((f) => f.id !== id)],
          });
        }
      }
    } catch (error) {
      console.error("Error deleting label:", error);
      throw error;
    }
  }

  async function replaceFile(file, noteId) {
    const note = setNote(noteId);
    if (note) {
      const files = note.files.filter((f) => f.id !== file.id);
      store.mutations.updateModel("notes", noteId, {
        ...note,
        files: [...files, file],
      });
    }
  }
  return {
    files,
    isLoading,
    fetchFiles,
    // createFile,
    updateFile,
    // deleteFile,
    replaceFile,
    getFileEditUrl,
    fetchFileById,
    searchFiles,
    createNoteFile,
    deleteNoteFile,
  };
}
