// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import { historyStack, isBackNavigation } from "./historyStore"; // Adjust path as needed
import HomeWrap from "../pages/HomeWrap.vue";
import store from "../store";

const routes = [
  // Grouped HomeWrap routes
  ...[
    "/new",
    "/new/:data",
    "/note/:id",
    "/note/:id/details",
    "/note/:id/security",
    "/note/:id/comments",
    "/note/:id/activity",
    "/preview/:id",
  ].map((path) => ({
    path,
    component: HomeWrap,
    meta: { requiresAuth: true, show: true, preview: path === "/preview/:id" },
  })),

  // Dynamic import for PreviewPage
  ...[
    "/note/:id/:token/:secret",
    "/note/:id/:token/:secret/details",
    "/note/:id/:token/:secret/security",
    "/note/:id/:token/:secret/comments",
    "/note/:id/:token/:secret/activity",
  ].map((path) => ({
    path: path,
    component: () => import("../pages/PreviewPage.vue"),
    meta: { requiresAuth: false, show: true },
  })),

  // Authenticated pages
  ...["/contacts", "/contacts/new", "/contacts/:id"].map((path) => ({
    path,
    component: () => import("../pages/ContactPage.vue"),
    meta: { requiresAuth: true, isNew: path === "/contacts/new" },
    props: true,
  })),
  ...["/chats", "/chats/:id"].map((path) => ({
    path,
    component: () => import("../pages/MessagesPage.vue"),
    meta: { requiresAuth: true },
  })),
  ...["/profile", "/profile/edit"].map((path) => ({
    path,
    component: () => import("../pages/ProfilePage.vue"),
    meta: { requiresAuth: true, edit: path === "/profile/edit" },
  })),
  ...["/profiles/:userId"].map((path) => ({
    path,
    component: () => import("../pages/UsersProfile.vue"),
    meta: { requiresAuth: true },
    props: true,
  })),
  {
    path: "/settings",
    component: () => import("../pages/SettingsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    component: () => import("../pages/ChangePasswordPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-password/:token",
    component: () => import("../pages/ResetPasswordPage.vue"),
    meta: { requiresAuth: true },
  },

  // Admin page
  {
    path: "/admin",
    component: () => import("../pages/AdminPage.vue"),
    meta: { requiresAdmin: true },
  },
  {
    path: "/app-dashboard",
    component: () => import("../pages/DashboardPage.vue"),
    meta: { requiresAdmin: true },
  },
  // Public pages
  { path: "/", component: HomeWrap },
  { path: "/login", component: () => import("../pages/LoginPage.vue") },
  {
    path: "/register",
    component: () => import("../pages/RegistrationPage.vue"),
  },
  {
    path: "/forgot-password",
    component: () => import("../pages/ForgotPasswordPage.vue"),
  },
  {
    path: "/verify-email",
    component: () => import("../pages/VerifyEmail.vue"),
  },
  {
    path: "/terms-of-service",
    component: () => import("../pages/TermsOfUsePage.vue"),
  },
  {
    path: "/privacy-policy",
    component: () => import("../pages/PrivacyPage.vue"),
  },
  {
    path: "/landing",
    component: () => import("../pages/LandingPage.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  linkActiveClass: "active-nav",
  linkExactActiveClass: "exact-active-nav",
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store.state.user; // Assuming you have user info in a global store

  if (to.meta.requiresAuth && !user) {
    next(`/login?return_url=${to.path}`);
  } else if (to.meta.requiresAdmin && (!user || user.roleId !== 1)) {
    next(`/login?return_url=${to.path}`);
  } else {
    // Check if the navigation is backward
    if (
      historyStack.value.length > 1 &&
      historyStack.value[historyStack.value.length - 2] === to.fullPath
    ) {
      isBackNavigation.value = true;
    } else {
      isBackNavigation.value = false;
    }
    next();
  }
});

router.afterEach((to) => {
  if (isBackNavigation.value) {
    if (to.fullPath === "/") {
      historyStack.value = ["/"];
    } else {
      historyStack.value.pop();
    }
  } else {
    const toFP = decodeURIComponent(to.fullPath);

    if (
      historyStack.value[historyStack.value.length - 1] !== toFP &&
      decodeURIComponent(historyStack.value[historyStack.value.length - 1]) !==
        toFP
    ) {
      if (to?.params?.secret) {
        // ensures it stays encoded.
        historyStack.value.push(
          `/note/${to?.params?.id}/${to?.params?.token}/${encodeURIComponent(
            decodeURIComponent(to?.params?.secret)
          )}`
        );
      } else {
        historyStack.value.push(toFP);
      }
    }
  }
});

export default router;
