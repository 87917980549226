import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";
import { generateKeywordTokens } from "@/utils/helpers";
import {
  decryptSymmetricKey,
  decryptContent,
  // updateContent,
  // generateSymmetricKey,
  encryptContent,
  encryptKeywords,
} from "@/utils/cryptoHelpers";

const preSaveMod = async (data, note) => {
  let key = note?.symmetricKey;
  if (!data || !note) {
    return data;
  }
  console.log("preSaveMod", data, note);
  const model = { ...data };
  if (!key) {
    let encryptedSymmetricKey = note?.encryptedSymmetricKey;
    key = await decryptSymmetricKey(encryptedSymmetricKey);
  }
  if (model.description?.trim() > "") {
    const { encryptedContent, iv } = await encryptContent(
      model.description.trim(),
      key
    );
    let tokens = generateKeywordTokens(
      model.description?.trim()?.toLowerCase()
    );
    model.description = encryptedContent;
    model.iv = iv;
    model.keywordTokens = await encryptKeywords(tokens);
  }
  return model;
};
export const postLoadMod = async (data, note, key) => {
  let symmetricKey = key || note?.symmetricKey;
  if (!data || !note) {
    return data;
  }
  const model = { ...data };
  if (!symmetricKey) {
    let encryptedSymmetricKey = note?.encryptedSymmetricKey;
    symmetricKey = await decryptSymmetricKey(encryptedSymmetricKey);
  }
  if (model.description && model.iv) {
    // Decrypt the encrypted description
    model.description = await decryptContent(
      model.description,
      model.iv,
      symmetricKey
    );
  }

  return model;
};

const setNote = (noteId) => {
  let note = store.state.notes?.find((note) => note.id === noteId);
  if (store.state.tokenNote && noteId && store.state.tokenNote?.id === noteId) {
    note = store.state.tokenNote;
  }
  return note;
};
export function useTaskStore() {
  const isLoading = ref(false);
  const tasks = computed(() => store.state.tasks);

  async function fetchTasks(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/tasks", { params: query });
      store.mutations.setState("tasks", response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createTask(taskData) {
    try {
      const encrytedData = await preSaveMod(taskData, note);
      const response = await api.post("/api/tasks", encrytedData);

      let note = setNote(taskData.noteId);

      const decrypted = await postLoadMod(response.data, note);
      if (note) {
        if (!note.isTokenNote) {
          store.mutations.updateModel("notes", response.data.noteId, {
            ...note,
            tasks: [...note.tasks, decrypted],
          });
        } else if (note.isTokenNote) {
          store.mutations.setState("tokenNote", {
            ...note,
            tasks: [...note.tasks, decrypted],
          });
        }
      }
      return decrypted;
    } catch (error) {
      console.error("Error creating task:", error);
      throw error;
    }
  }

  async function updateTask(id, taskData) {
    try {
      let note = setNote(taskData.noteId);
      const encrytedData = await preSaveMod(taskData, note);

      const response = await api.put(`/api/tasks/${id}`, encrytedData);

      const decrypted = await postLoadMod(response.data, note);
      console.log("decrypted");
      note = setNote(taskData.noteId);
      const index = note?.tasks?.map((t) => t.id)?.indexOf(response.data.id);
      if (index >= 0) {
        const updatedTasks = [...note.tasks];
        updatedTasks[index] = decrypted;
        if (!note.isTokenNote) {
          store.mutations.updateModel("notes", response.data.noteId, {
            ...note,
            tasks: updatedTasks,
          });
        } else if (note.isTokenNote) {
          store.mutations.setState("tokenNote", {
            ...note,
            tasks: updatedTasks,
          });
        }
      }
      return decrypted;
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  }

  async function deleteTask(id, noteId) {
    try {
      await api.delete(`/api/tasks/${id}`);
      const note = store.state.notes?.find((note) => note.id === noteId);
      if (note) {
        if (!note.isTokenNote) {
          store.mutations.updateModel("notes", noteId, {
            ...note,
            tasks: note.tasks.filter((t) => t.id !== id),
          });
        } else if (note.isTokenNote) {
          store.mutations.setState("tokenNote", {
            ...note,
            tasks: note.tasks.filter((t) => t.id !== id),
          });
        }
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      throw error;
    }
  }

  async function fetchTaskById(taskId) {
    try {
      const response = await api.get(`/api/tasks/${taskId}`);
      store.mutations.addModel("tasks", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching task by id:", error);
      throw error;
    }
  }

  async function searchTasks(query) {
    try {
      const response = await api.get("/api/tasks/search", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching tasks:", error);
      throw error;
    }
  }

  return {
    tasks,
    isLoading,
    fetchTasks,
    createTask,
    updateTask,
    deleteTask,
    fetchTaskById,
    searchTasks,
  };
}
