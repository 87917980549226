import store from "@/store";

// Convert an ArrayBuffer to a Base64 string
const bufferToBase64 = (buffer) => {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)));
};

// Convert a Base64 string to a Uint8Array
const base64ToUint8Array = (base64) => {
  const binaryString = atob(base64);
  return Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
};

// Convert a Base64 string to an ArrayBuffer
const base64ToArrayBuffer = (base64) => {
  return base64ToUint8Array(base64).buffer;
};

export const generateSymmetricKey = async () => {
  return await crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256, // 256-bit encryption
    },
    true,
    ["encrypt", "decrypt"]
  );
};

export const encryptContent = async (content, symmetricKey) => {
  if (!content) {
    return {};
  }
  const encoder = new TextEncoder();
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random initialization vector (IV)
  const encryptedContentBuffer = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    symmetricKey,
    encoder.encode(content) // Encode content to Uint8Array
  );

  return {
    encryptedContent: btoa(
      String.fromCharCode(...new Uint8Array(encryptedContentBuffer))
    ), // Convert to Base64
    iv: btoa(String.fromCharCode(...iv)), // Convert IV to Base64
  };
};

export const decryptContent = async (encryptedContent, iv, symmetricKey) => {
  if (!encryptedContent || !symmetricKey || !iv) {
    return encryptedContent;
  }
  try {
    const encryptedContentBuffer = Uint8Array.from(
      atob(encryptedContent),
      (char) => char.charCodeAt(0)
    );
    const ivBuffer = Uint8Array.from(atob(iv), (char) => char.charCodeAt(0));

    const decryptedContentBuffer = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: ivBuffer,
      },
      symmetricKey,
      encryptedContentBuffer
    );

    return new TextDecoder().decode(decryptedContentBuffer); // Convert decrypted content back to string
  } catch (e) {
    console.log("error", e);
  }
};

export const encryptSymmetricKey = async (symmetricKey, publicKeyBase64) => {
  const publicKeyBinary = Uint8Array.from(atob(publicKeyBase64), (char) =>
    char.charCodeAt(0)
  );

  // Import the public key
  const publicKey = await crypto.subtle.importKey(
    "spki",
    publicKeyBinary,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    false,
    ["encrypt"]
  );

  // Export the symmetric key as raw data
  const rawSymmetricKey = await crypto.subtle.exportKey("raw", symmetricKey);

  // Encrypt the symmetric key with the public key
  const encryptedSymmetricKeyBuffer = await crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    publicKey,
    rawSymmetricKey
  );

  return btoa(
    String.fromCharCode(...new Uint8Array(encryptedSymmetricKeyBuffer))
  ); // Convert to Base64
};

export const decryptSymmetricKey = async (encryptedSymmetricKeyBase64) => {
  const privateKeyBase64 = store.state.privateKey;
  const privateKeyBinary = Uint8Array.from(atob(privateKeyBase64), (char) =>
    char.charCodeAt(0)
  );
  const encryptedSymmetricKey = Uint8Array.from(
    atob(encryptedSymmetricKeyBase64),
    (char) => char.charCodeAt(0)
  );

  // Import the private key
  const privateKey = await crypto.subtle.importKey(
    "pkcs8",
    privateKeyBinary,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    false,
    ["decrypt"]
  );

  // Decrypt the symmetric key
  const decryptedKeyBuffer = await crypto.subtle.decrypt(
    {
      name: "RSA-OAEP",
    },
    privateKey,
    encryptedSymmetricKey
  );

  // Import the decrypted symmetric key back as a CryptoKey
  return await crypto.subtle.importKey(
    "raw",
    decryptedKeyBuffer,
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );
};

/**
 * Derives a strong encryption key from a temporary access token using PBKDF2.
 * @param {string} accessToken - The temporary collaborator's access token.
 * @param {Uint8Array} salt - A unique salt to ensure security.
 * @returns {Promise<string>} - The derived encryption key in Base64 format.
 */
// export const deriveEncryptionKey = async (accessToken, salt) => {
//   const encoder = new TextEncoder();
//   const keyMaterial = await crypto.subtle.importKey(
//     "raw",
//     encoder.encode(accessToken), // Convert accessToken to raw key material
//     { name: "PBKDF2" },
//     false,
//     ["deriveKey"]
//   );

//   const derivedKey = await crypto.subtle.deriveKey(
//     {
//       name: "PBKDF2",
//       salt,
//       iterations: 100000, // High iteration count for security
//       hash: "SHA-256",
//     },
//     keyMaterial,
//     { name: "AES-GCM", length: 256 },
//     true,
//     ["encrypt", "decrypt"]
//   );

//   const exportedKey = await crypto.subtle.exportKey("raw", derivedKey);
//   return new Uint8Array(exportedKey);
// };
export const deriveEncryptionKey = async (accessToken, salt) => {
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    encoder.encode(accessToken), // Convert accessToken to raw key material
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  // Derive a 16-byte (128-bit) key
  const derivedKey = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 100000, // Secure iteration count
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 128 }, // Ensure 128-bit (16-byte) key
    true,
    ["encrypt", "decrypt"]
  );

  // Export the key to raw format
  return new Uint8Array(await crypto.subtle.exportKey("raw", derivedKey));
};

export const updateContent = async (content, symmetricKey) => {
  return await encryptContent(content, symmetricKey);
};

export const addCollaborator = async (
  symmetricKey,
  newCollaboratorPublicKeyBase64
) => {
  return await encryptSymmetricKey(
    symmetricKey,
    newCollaboratorPublicKeyBase64
  );
};

// Keywords/search

// export const deriveUserSearchKey = async () => {
//   if (store.state.userSearchKey) {
//     return store.state.userSearchKey;
//   }
//   const userPrivateKey = store.state.privateKey;
//   const systemSalt = store.state.systemSalt;
//   const encoder = new TextEncoder();
//   const keyMaterial = await crypto.subtle.importKey(
//     "raw",
//     encoder.encode(userPrivateKey), // User's private key or passphrase
//     { name: "PBKDF2" },
//     false,
//     ["deriveKey"]
//   );
//   const key = await crypto.subtle.deriveKey(
//     {
//       name: "PBKDF2",
//       salt: encoder.encode(systemSalt), // Shared system-wide constant
//       iterations: 100000,
//       hash: "SHA-256",
//     },
//     keyMaterial,
//     { name: "AES-GCM", length: 256 },
//     true,
//     ["encrypt", "decrypt"]
//   );
//   store.mutations.setState("userSearchKey", key);
//   return key;
// };

const deriveIV = async (keyword) => {
  const encoder = new TextEncoder();
  const hash = await crypto.subtle.digest("SHA-256", encoder.encode(keyword));
  return new Uint8Array(hash.slice(0, 12)); // Use the first 12 bytes as IV
};

export const decryptKeyword = async (encryptedText, userSearchKey) => {
  // Convert encrypted text from Base64 to ArrayBuffer
  const encryptedBuffer = new Uint8Array(
    atob(encryptedText)
      .split("")
      .map((char) => char.charCodeAt(0))
  ).buffer;

  // Derive IV from the original keyword (assumes deriveIV is deterministic)
  const iv = await deriveIV(encryptedText);

  // Decrypt the keyword
  const decryptedContentBuffer = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv,
    },
    userSearchKey,
    encryptedBuffer
  );

  return new TextDecoder().decode(decryptedContentBuffer); // Convert back to text
};

export const encryptKeyword = async (t) => {
  const keyword = t?.toLowerCase();
  const base64Key = store.state.searchKey; // Get stored Base64 key
  if (!base64Key) {
    throw new Error("Search key not found in store.");
  }

  // Convert Base64 to ArrayBuffer
  const rawKey = base64ToArrayBuffer(base64Key);

  // Import as CryptoKey
  const userSearchKey = await crypto.subtle.importKey(
    "raw",
    rawKey,
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );
  const iv = await deriveIV(keyword); // Derive IV from the keyword
  const encoder = new TextEncoder();

  const encryptedContentBuffer = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    userSearchKey,
    encoder.encode(keyword)
  );

  return btoa(String.fromCharCode(...new Uint8Array(encryptedContentBuffer))); // Convert to Base64
};
export const encryptSearchTerms = async (searchTerms) => {
  const base64Key = store.state.searchKey; // Get stored Base64 key
  if (!base64Key) {
    throw new Error("Search key not found in store.");
  }

  // Convert Base64 to ArrayBuffer
  const rawKey = base64ToArrayBuffer(base64Key);

  // Import as CryptoKey
  const userSearchKey = await crypto.subtle.importKey(
    "raw",
    rawKey,
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );
  const encryptSingleTerm = async (t) => {
    const term = t?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase();
    const iv = await deriveIV(term); // Derive IV from the term
    const encoder = new TextEncoder();

    const encryptedBuffer = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv,
      },
      userSearchKey,
      encoder.encode(term)
    );

    return btoa(String.fromCharCode(...new Uint8Array(encryptedBuffer))); // Convert to Base64
  };
  // Encrypt all search terms in parallel
  const encryptedTerms = await Promise.all(searchTerms.map(encryptSingleTerm));
  return encryptedTerms; // Return an array of encrypted terms
};

export const encryptKeywords = async (keywords) => {
  if (!keywords) {
    return [];
  }
  const base64Key = store.state.searchKey; // Get stored Base64 key
  if (!base64Key) {
    throw new Error("Search key not found in store.");
  }

  // Convert Base64 to ArrayBuffer
  const rawKey = base64ToArrayBuffer(base64Key);

  // Import as CryptoKey
  const userSearchKey = await crypto.subtle.importKey(
    "raw",
    rawKey,
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );

  const encryptedKeywords = await Promise.all(
    keywords.map((keyword) =>
      encryptKeyword(keyword?.replace(/[^a-zA-Z0-9]/g, ""), userSearchKey)
    )
  );

  return encryptedKeywords; // Store these in the database
};

export const decryptKeywords = async (encryptedKeywords) => {
  const base64Key = store.state.searchKey; // Get stored Base64 key
  if (!base64Key) {
    throw new Error("Search key not found in store.");
  }

  // Convert Base64 to ArrayBuffer
  const rawKey = base64ToArrayBuffer(base64Key);
  // Import as CryptoKey
  const userSearchKey = await crypto.subtle.importKey(
    "raw",
    rawKey,
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );
  const decryptedKeywords = await Promise.all(
    encryptedKeywords.map((encryptedKeyword) =>
      decryptKeyword(encryptedKeyword, userSearchKey)
    )
  );

  return decryptedKeywords;
};

/**
 * Encrypts a shared symmetric key using a collaborator's RSA public key.
 * @param {CryptoKey | string} collaboratorPublicKeyBase64 - The collaborator's public key in Base64 format.
 * @param {CryptoKey} sharedKey - The AES-GCM symmetric key to encrypt.
 * @returns {Promise<string>} - The encrypted shared key as a Base64 string.
 */
export const encryptSharedKey = async (
  collaboratorPublicKeyBase64,
  sharedKey
) => {
  // Decode Base64 public key
  const publicKeyBinary = Uint8Array.from(
    atob(collaboratorPublicKeyBase64),
    (char) => char.charCodeAt(0)
  );

  // Import the public key
  const publicKey = await crypto.subtle.importKey(
    "spki",
    publicKeyBinary,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    false,
    ["encrypt"]
  );

  // Export the symmetric key as raw data
  const rawSharedKey = await crypto.subtle.exportKey("raw", sharedKey);

  // Encrypt the symmetric key using the public key
  const encryptedSharedKeyBuffer = await crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    publicKey,
    rawSharedKey
  );

  // Convert encrypted key to Base64 for easy storage/transmission
  return btoa(String.fromCharCode(...new Uint8Array(encryptedSharedKeyBuffer)));
};

export const importRSAPrivateKey = async (pemKey) => {
  const pemHeader = "-----BEGIN PRIVATE KEY-----";
  const pemFooter = "-----END PRIVATE KEY-----";
  const pemContents = pemKey
    .replace(pemHeader, "")
    .replace(pemFooter, "")
    .replace(/\n/g, "");
  const binaryDer = Uint8Array.from(atob(pemContents), (c) =>
    c.charCodeAt(0)
  ).buffer;

  return crypto.subtle.importKey(
    "pkcs8",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["decrypt"]
  );
};

export const decryptUserSearchKey = async (
  encryptedUserSearchKey,
  userPrivateKeyPem
) => {
  try {
    // Import the RSA private key
    const userPrivateKey = await importRSAPrivateKey(userPrivateKeyPem);

    // Convert Base64 to ArrayBuffer
    const encryptedKeyBuffer = new Uint8Array(
      base64ToUint8Array(encryptedUserSearchKey)
    ).buffer;

    // Decrypt the search key
    const decryptedUserSearchKeyBuffer = await crypto.subtle.decrypt(
      { name: "RSA-OAEP" },
      userPrivateKey,
      encryptedKeyBuffer
    );

    if (decryptedUserSearchKeyBuffer.byteLength !== 32) {
      throw new Error("Invalid AES Key Length: Expected 32 bytes");
    }

    // Import the decrypted key as an AES-GCM CryptoKey
    const searchKey = await crypto.subtle.importKey(
      "raw",
      decryptedUserSearchKeyBuffer,
      { name: "AES-GCM" },
      true,
      ["encrypt", "decrypt"]
    );
    // ✅ Log key safely

    // ✅ Export the key as raw bytes
    const exportedKey = await crypto.subtle.exportKey("raw", searchKey);

    // ✅ Convert raw key bytes to Base64
    const base64Key = bufferToBase64(exportedKey);

    return base64Key; // ✅ Return Base64-encoded key so it can be stored
  } catch (error) {
    console.error("Error decrypting user search key:", error);
    throw error;
  }
};

const deriveKeyFromPassword = async (password, saltBase64) => {
  const salt = base64ToUint8Array(saltBase64);
  const passwordKey = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  return crypto.subtle.deriveKey(
    { name: "PBKDF2", salt, iterations: 100000, hash: "SHA-256" },
    passwordKey,
    { name: "AES-GCM", length: 256 },
    false,
    ["decrypt"]
  );
};

export const decryptPrivateKey = async (
  encryptedPrivateKeyBase64,
  password,
  ivBase64,
  saltBase64
) => {
  try {
    // Convert Base64 to Uint8Array
    const iv = Uint8Array.from(atob(ivBase64), (c) => c.charCodeAt(0));
    console.log("IV Length:", iv.length); // Should be 12

    const encryptedPrivateKey = base64ToUint8Array(
      encryptedPrivateKeyBase64
    )?.buffer;
    console.log("Encrypted Key Length:", encryptedPrivateKey.byteLength); // Must match encryption output

    const key = await deriveKeyFromPassword(password, saltBase64);
    console.log("Derived Key:", key);

    console.log("Attempting decryption...", encryptedPrivateKey);

    const decryptedPrivateKeyBuffer = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      key,
      encryptedPrivateKey
    );

    console.log("Decryption Successful");

    // ✅ Convert ArrayBuffer back to the original PEM string
    const decryptedPrivateKey = new TextDecoder().decode(
      decryptedPrivateKeyBuffer
    );
    console.log("Decrypted Private Key:", decryptedPrivateKey);

    return decryptedPrivateKey;
  } catch (error) {
    console.error("Error decrypting private key:", error);
    throw error;
  }
};

export const encryptSymmetricKeyForTempCollab = async (
  symmetricKey,
  accessToken,
  isPublic
) => {
  // Step 1: Generate a random userSecret
  const userSecret = crypto.getRandomValues(new Uint8Array(16));

  // Step 2: Encrypt the userSecret with the symmetricKey
  const userSecretIv = crypto.getRandomValues(new Uint8Array(12));
  const encryptedUserSecret = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv: userSecretIv },
    symmetricKey,
    userSecret
  );

  // Step 3: Generate salt and derive a key from accessToken
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const derivedKey = await deriveEncryptionKey(accessToken, salt);

  // Step 4: Combine userSecret and accessToken-derived key to encrypt symmetricKey
  const combinedKey = await crypto.subtle.importKey(
    "raw",
    new Uint8Array([...userSecret, ...derivedKey]), // Concatenate userSecret & derivedKey
    { name: "AES-GCM" },
    false,
    ["encrypt", "decrypt"]
  );

  // Step 5: Encrypt the symmetric key with the combined key
  const symmetricKeyIv = crypto.getRandomValues(new Uint8Array(12));
  const encryptedKey = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv: symmetricKeyIv },
    combinedKey,
    await crypto.subtle.exportKey("raw", symmetricKey)
  );
  let searchKeyIv;
  let encryptedSearchKeyBuffer;
  let accessSecret;
  let accessSecretIv;
  let encryptedAccessSecret;
  if (!isPublic) {
    // Step 6 Encrypt the searchKey with the symetricKey
    const encoder = new TextEncoder();
    searchKeyIv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random initialization vector (IV)
    encryptedSearchKeyBuffer = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: searchKeyIv,
      },
      symmetricKey,
      encoder.encode(store.state.searchKey) // Encode content to Uint8Array
    );

    // encrypt the edit secret
    const randomBytes = crypto.getRandomValues(new Uint8Array(32));
    accessSecret = btoa(String.fromCharCode(...randomBytes));
    const { encryptedContent, iv } = await encryptContent(
      accessSecret,
      symmetricKey
    );
    accessSecretIv = iv;
    encryptedAccessSecret = encryptedContent;
  }
  // Return the encrypted values (store these in DB)
  return {
    accessToken,
    encryptedSymmetricKey: btoa(
      String.fromCharCode(...new Uint8Array(encryptedKey))
    ),
    iv: btoa(String.fromCharCode(...symmetricKeyIv)),
    salt: btoa(String.fromCharCode(...salt)),
    encryptedUserSecret: btoa(
      String.fromCharCode(...new Uint8Array(encryptedUserSecret))
    ),
    userSecretIv: btoa(String.fromCharCode(...userSecretIv)),
    ...(!isPublic
      ? {
          encryptedSearchKey: btoa(
            String.fromCharCode(...new Uint8Array(encryptedSearchKeyBuffer))
          ), // Convert to Base64
          searchKeyIv: btoa(String.fromCharCode(...searchKeyIv)), // Convert IV to Base64
          accessSecret,
          encryptedAccessSecret, // Convert to Base64
          accessSecretIv,
        }
      : {}),
  };
};

export const decryptSymmetricKeyForTempCollab = async (
  userSecretBase64, // Decrypted userSecret passed in
  encryptedSymmetricKeyBase64,
  accessToken,
  symmetricKeyIvBase64,
  saltBase64
) => {
  if (
    !userSecretBase64 ||
    !encryptedSymmetricKeyBase64 ||
    !accessToken ||
    !symmetricKeyIvBase64 ||
    !saltBase64
  ) {
    console.log("can not decryptSymmetricKeyForTempCollab");
    return;
  }
  console.log(
    "userSecret",
    userSecretBase64, // Decrypted userSecret passed in
    "encryptedSymmetricKeyBase64",
    encryptedSymmetricKeyBase64,
    "accessToken",
    accessToken,
    "symmetricKeyIvBase64",
    symmetricKeyIvBase64,
    "saltBase64",
    saltBase64
  );
  // Step 1: Decode values
  const salt = Uint8Array.from(atob(saltBase64), (c) => c.charCodeAt(0));
  const symmetricKeyIv = Uint8Array.from(atob(symmetricKeyIvBase64), (c) =>
    c.charCodeAt(0)
  );
  console.log("salt", salt.length);

  const userSecret = Uint8Array.from(atob(userSecretBase64), (c) =>
    c.charCodeAt(0)
  );
  // Step 2: Derive the key from accessToken

  const derivedKey = await deriveEncryptionKey(accessToken, salt);

  if (derivedKey.length !== 16) {
    throw new Error("Derived key must be exactly 16 bytes.");
  }
  // Ensure userSecret is the correct length (16 bytes)
  if (userSecret.length !== 16) {
    throw new Error("User secret must be exactly 16 bytes.");
  }

  // Step 3: Construct the combined key using userSecret + accessToken-derived key
  const combinedKey = await crypto.subtle.importKey(
    "raw",
    new Uint8Array([...userSecret, ...derivedKey]),
    { name: "AES-GCM" },
    false,
    ["encrypt", "decrypt"]
  );

  // Step 4: Decrypt the symmetric key
  const encryptedKey = Uint8Array.from(atob(encryptedSymmetricKeyBase64), (c) =>
    c.charCodeAt(0)
  );
  const decryptedKeyBuffer = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv: symmetricKeyIv },
    combinedKey,
    encryptedKey
  );

  // Step 5: Convert decrypted buffer back to CryptoKey
  const symmetricKey = await crypto.subtle.importKey(
    "raw",
    decryptedKeyBuffer,
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );

  return symmetricKey;
};

export const decryptUserSecret = async (
  symmetricKey,
  encryptedUserSecretBase64,
  userSecretIvBase64
) => {
  try {
    if (!userSecretIvBase64) {
      return;
    }
    // Decode IV and encrypted user secret
    const userSecretIv = Uint8Array.from(atob(userSecretIvBase64), (c) =>
      c.charCodeAt(0)
    );
    const encryptedUserSecret = Uint8Array.from(
      atob(encryptedUserSecretBase64),
      (c) => c.charCodeAt(0)
    );

    // Decrypt the userSecret using the symmetricKey
    const decryptedUserSecretBuffer = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: userSecretIv },
      symmetricKey,
      encryptedUserSecret
    );

    // Convert the decrypted buffer to a string (or hex/base64 if needed)
    return btoa(
      String.fromCharCode(...new Uint8Array(decryptedUserSecretBuffer))
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export function generatePublicShareToken(length = 32) {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
}

export async function encryptFile(file, key) {
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV

  const fileArrayBuffer = await file.arrayBuffer();
  const encryptedBuffer = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key, // Use key directly, no need to import it
    fileArrayBuffer
  );

  // Create a Blob from the encrypted data
  const encryptedBlob = new Blob([encryptedBuffer], { type: file.type });

  // Maintain the original file extension
  const encryptedFile = new File([encryptedBlob], file.name, {
    type: file.type,
    lastModified: Date.now(),
  });

  encryptedFile.iv = btoa(String.fromCharCode(...iv)); // Attach IV for later decryption
  return encryptedFile;
}

export async function decryptFile(
  encryptedBuffer,
  key,
  iv,
  fileType = "image/jpeg"
) {
  if (!encryptedBuffer || !key || !iv) {
    return encryptedBuffer;
  }
  try {
    const decryptedBuffer = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      key, // Directly use the key, no need to import
      encryptedBuffer
    );

    return new Blob([decryptedBuffer], { type: fileType });
  } catch (error) {
    console.error("Decryption failed:", error);
    throw new Error("Decryption error");
  }
}
