import { computed, ref } from "vue";
import axios from "axios";
import store from "../store";
import api from "../utils/api";
import { postLoadMod as postUpdateTask } from "./useTaskStore";
import { postLoadMod as postUpdateFile } from "./useFileStore";
import {
  decryptSymmetricKey,
  updateContent,
  decryptContent,
  encryptKeywords,
  decryptSymmetricKeyForTempCollab,
  decryptUserSecret,
} from "../utils/cryptoHelpers";
import { generateKeywordTokens } from "../utils/helpers";

let lastQuery = null;
let cancelTokenSource;
export const encryptNote = async (note, { symmetricKey }) => {
  if (!note) {
    return;
  }
  const model = { ...note };
  console.log("model", model, note);
  const { security, content } = model;
  let keywordTokens = security ? [] : generateKeywordTokens(content);
  keywordTokens = await encryptKeywords(keywordTokens, symmetricKey);
  const { encryptedContent, iv } = await updateContent(content, symmetricKey);
  return {
    encryptedContent,
    iv,
    encryptedKeywordTokens: keywordTokens,
  };
};

export const decryptNotesArray = async (notes, { symmetricKey }) => {
  let key = symmetricKey;

  const privateKeyBase64 = store.state.privateKey;
  const decryptedNotes = await Promise.all(
    notes.map(async (note) => {
      try {
        const {
          content,
          iv,
          encryptedSymmetricKey,
          encryptedSearchKey,
          searchKeyIv,
          encryptedAccessSecret,
          accessSecretIv,
          publicAccess,
        } = note;
        if (privateKeyBase64 && !key) {
          // Step 1: Decrypt the symmetric key
          key = await decryptSymmetricKey(
            encryptedSymmetricKey,
            privateKeyBase64
          );
          note.symmetricKey = key;
        }
        // Step 2: Decrypt the note content
        const decryptedContent = await decryptContent(content, iv, key);

        if (note.tasks?.length > 0) {
          note.tasks = await Promise.all(
            note.tasks.map(async (task) =>
              postUpdateTask(task, note, symmetricKey)
            )
          );
        }
        if (note.files?.length > 0) {
          note.files = await Promise.all(
            note.files.map(async (f) => postUpdateFile(f, note, symmetricKey))
          );
        }

        if (encryptedSearchKey && searchKeyIv) {
          const searchKey = await decryptContent(
            encryptedSearchKey,
            searchKeyIv,
            key
          );
          if (searchKey) {
            store.mutations.setState("searchKey", searchKey);
          }
        }

        if (encryptedAccessSecret && accessSecretIv) {
          const accessSecret = await decryptContent(
            encryptedAccessSecret,
            accessSecretIv,
            key
          );
          if (accessSecret) {
            note.accessSecret = accessSecret;
          }
        }

        if (publicAccess && publicAccess?.encryptedUserSecret) {
          const userSecret = await decryptUserSecret(
            note?.symmetricKey ?? key,
            publicAccess?.encryptedUserSecret,
            publicAccess?.userSecretIv
          );
          if (userSecret) {
            note.publicAccess.decryptedUserSecret =
              encodeURIComponent(userSecret);
          }
        }
        return { ...note, content: decryptedContent };
      } catch (e) {
        console.log(e);
        return note;
      }
    })
  );

  return decryptedNotes;
};

export function useNoteStore() {
  const isLoading = ref(false);
  const notes = computed(() => store.state.notes);

  async function fetchNotes(query) {
    try {
      lastQuery = query;
      isLoading.value = true;
      // Cancel the previous request if it's still pending
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Cancelled duplicate request");
      }

      // Create a new cancel token for the new request
      cancelTokenSource = axios.CancelToken.source();
      //   store.mutations.setState("notes", []);
      const response = await api.get("/api/notes", {
        params: query,
        cancelToken: cancelTokenSource.token,
      });

      const decryptedData = await decryptNotesArray(response.data, {});
      store.mutations.setState("notes", decryptedData);
      isLoading.value = false;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        store.mutations.setState("notes", []);
        console.error("Error fetching notes:", error);
        isLoading.value = false;
      }
    }
  }

  async function reFetchNotes() {
    try {
      // Cancel the previous request if it's still pending
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Cancelled duplicate request");
      }

      // Create a new cancel token for the new request
      cancelTokenSource = axios.CancelToken.source();
      //   store.mutations.setState("notes", []);
      const response = await api.get("/api/notes", {
        params: lastQuery,
        cancelToken: cancelTokenSource.token,
      });
      const decryptedData = await decryptNotesArray(response.data, {});
      store.mutations.setState("notes", decryptedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        store.mutations.setState("notes", []);
        console.error("Error fetching notes:", error);
      }
    }
  }

  async function createNote(noteData) {
    try {
      const response = await api.post("/api/notes", noteData);
      //   store.mutations.addModel("notes", response.data);
      await fetchNotes(lastQuery);
      return response.data;
    } catch (error) {
      console.error("Error creating note:", error);
      throw error;
    }
  }

  async function updateNote(id, noteData) {
    try {
      if (noteData?.publicAccess?.decryptedUserSecret) {
        delete noteData.publicAccess.decryptedUserSecret;
      }
      const response = await api.put(`/api/notes/${id}`, noteData);
      const decryptedData = await decryptNotesArray([response.data], {});
      store.mutations.updateModel("notes", id, decryptedData[0]);
      return response.data;
    } catch (error) {
      console.error("Error updating note:", error);
      throw error;
    }
  }

  async function deleteNote(id) {
    try {
      await api.delete(`/api/notes/${id}`);
      store.mutations.removeModel("notes", id);
    } catch (error) {
      console.error("Error deleting note:", error);
      throw error;
    }
  }

  async function fetchNoteById(noteId, { lazy, skipMutations }) {
    try {
      let isTokenNote = false;
      let note = store.state.notes?.find((note) => note.id === noteId);
      if (!note) {
        // check tokenNote
        if (store.state.tokenNote?.id === noteId) {
          isTokenNote = true;
          note = store.state.tokenNote;
        }
      }
      if (!lazy || !note) {
        const response = await api.get(`/api/notes/${noteId}`);
        if (!note) {
          note = (await decryptNotesArray([response.data], {}))[0];

          if (!store.state.notes?.find((note) => note.id === noteId)) {
            if (!skipMutations) store.mutations.addModel("notes", note);
          }
        } else {
          if (!skipMutations && !isTokenNote)
            store.mutations.updateModel(
              "notes",
              noteId,
              (await decryptNotesArray([response.data], {}))[0]
            );
        }
      }
      return note;
    } catch (error) {
      console.error("Error fetching note by id:", error);
      throw error;
    }
  }

  async function fetchNoteByIdAndToken(noteId, token, secret) {
    try {
      const response = await api.get(`/api/notes/${noteId}/${token}`);
      const note = response.data;

      let symmetricKey;
      if (note?.encryptedSymmetricKey) {
        symmetricKey = await decryptSymmetricKeyForTempCollab(
          secret,
          note.encryptedSymmetricKey,
          token,
          note.userIv,
          note.salt
        );
      } else {
        symmetricKey = await decryptSymmetricKeyForTempCollab(
          secret,
          note.publicAccess.encryptedSymmetricKey,
          note.publicAccess.accessToken,
          note.publicAccess.iv,
          note.publicAccess.salt
        );
      }
      // console.log("symmetricKey", symmetricKey);
      const noteD = (await decryptNotesArray([note], { symmetricKey }))[0];
      if (noteD) {
        noteD.symmetricKey = symmetricKey;
      }
      return noteD;
    } catch (error) {
      console.error("Error fetching note by id:", error);
      throw error;
    }
  }
  async function searchNotes(query) {
    try {
      return await api.get("/api/notes", { params: query })?.data;
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  }

  return {
    notes,
    lastQuery,
    isLoading,
    fetchNotes,
    reFetchNotes,
    createNote,
    updateNote,
    deleteNote,
    fetchNoteById,
    fetchNoteByIdAndToken,
    searchNotes,
  };
}
