import { computed, defineAsyncComponent } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { BrokerPlugin } from "../plugins/broker-plugin";
import {
  handlePositionUpdate,
  isNative,
  // manuallySetLastKnownPosition,
} from "../utils/locationHelper"; // Import the shared helper
import { useDialogService } from "../hooks/useDialogService";
import { usePreferenceStore } from "../hooks/usePrefrenceStore";

const GeoDialog = defineAsyncComponent(() =>
  import("../components/GeoDialog.vue")
);

import store from "../store";
const MIN_POLLING_INTERVAL = 30000; // Start polling every 30 seconds
const MAX_POLLING_INTERVAL = 60000; // Max backof
let pollingInterval = MIN_POLLING_INTERVAL;
let abortController;
let isTracking = false;
let isPolling = false;
let pollingTimeout = null; // Timeout reference for polling

export function useDeviceLocation() {
  let checkinAlerts = computed(() => store.state.checkinAlerts);

  async function checkPermissions() {
    const showPrompt = async () => {
      const dialogService = useDialogService();
      const dialog = dialogService.get();
      if (!dialog) {
        return true;
      }
      return new Promise((resolve) => {
        const d = dialog.open(GeoDialog, {
          props: {
            showHeader: false,
            draggable: false,
            blockScroll: true,
            modal: true,
          },
          emits: {
            onAccept: () => {
              d.close();
              resolve(true); // Resolve with `true` when accepted
            },
            onDecline: () => {
              d.close();
              resolve(false); // Resolve with `false` when declined
            },
          },
        });
      });
    };
    if (isNative) {
      const permResult = await Geolocation.checkPermissions();

      console.log("permResult (When In Use)", permResult);

      if (permResult.location === "denied") {
        console.error("Location permissions denied.");
        return false;
      }
      if (permResult.location === "granted") {
        return true;
      }
      if (permResult.location === "prompt") {
        return await showPrompt();
      }
    } else {
      const { preferences } = usePreferenceStore();
      const result = preferences?.value?.allowLocation
        ? { state: "granted" }
        : await navigator.permissions.query({ name: "geolocation" });

      if (result.state === "granted") {
        return true;
      } else if (result.state === "prompt") {
        return await showPrompt();
      } else {
        // TODO: denied
        return false;
      }
    }
  }

  async function requestLocationPermissions() {
    const shouldRequestPermissions = await checkPermissions();
    if (shouldRequestPermissions) {
      if (isNative) {
        console.log("isNative", isNative, BrokerPlugin);
        try {
          // Start Monitoring
          const res = await BrokerPlugin.command({
            action: "startMonitoring",
          });
          console.log("Command sent successfully", res);
          return true;
        } catch (error) {
          console.error("Error invoking command:", error);
          return false;
        }
        // First, request "While Using the App" (When In Use) permission
        // const permResult = await Geolocation.requestPermissions({
        //   permissions: ["location"],
        // });
        // console.log("permResult (When In Use)", permResult);
        // if (permResult.location === "denied") {
        //   console.error("Location permissions denied.");
        //   return false;
        // }
        // // Check if you need "Always Allow" permission
        // if (permResult.location === "granted") {
        //   // Here, you can either wait for some background feature to trigger
        //   // Or prompt the user manually to go to settings to enable "Always Allow"
        //   const alwaysPermissionResult = await Geolocation.requestPermissions({
        //     permissions: ["locationAlways"],
        //   });
        //   console.log("permResult (Always)", alwaysPermissionResult);
        //   if (alwaysPermissionResult.location === "denied") {
        //     console.error("Location 'Always Allow' denied.");
        //     // return false;
        //   }
      } else {
        // browser will do this by default with the next geo request
        return true;
      }
    } else {
      return false;
    }
  }

  // Function to poll location with backoff
  async function pollLocation() {
    if (!isTracking || isPolling) return; // Stop polling if tracking is not active
    if (abortController) {
      abortController.abort();
      abortController = null;
    }
    isPolling = true;
    abortController = new AbortController();
    try {
      console.log("pollLocation");
      if (isNative) {
        // Use Capacitor Geolocation for native platforms
        const position = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });
        handlePositionUpdate(position, null, {
          signal: abortController.signal,
        });
      } else if (navigator.geolocation) {
        // Use browser's geolocation for web platforms
        navigator.geolocation.getCurrentPosition(
          (position) => {
            handlePositionUpdate({ coords: position.coords }, null, {
              signal: abortController.signal,
            });
          },
          (err) => {
            console.error("Error getting location (web):", err);
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        throw new Error("Geolocation not supported on this platform.");
      }

      // Reset interval to minimum after successful update
      pollingInterval = MIN_POLLING_INTERVAL;
    } catch (err) {
      console.error("Error getting location:", err);

      // Increase the polling interval using backoff logic
      pollingInterval = Math.min(pollingInterval * 2, MAX_POLLING_INTERVAL);
    } finally {
      isPolling = false;
    }
    if (pollingTimeout) {
      clearTimeout(pollingTimeout); // Stop the polling
    }
    // Schedule the next poll with the updated interval
    pollingTimeout = setTimeout(pollLocation, pollingInterval);
  }

  async function startLocationTracking() {
    if (isTracking) return;

    if (isNative) {
      document.addEventListener("significantLocationChange", async (event) => {
        const locationData = event;
        console.log("Native sent location change to web", locationData);
        handlePositionUpdate(
          {
            coords: {
              latitude: locationData.latitude,
              longitude: locationData.longitude,
              accuracy: locationData.accuracy,
            },
          },
          null,
          { skipNotify: true }
        );
      });
    }

    const permissionsGranted = await requestLocationPermissions();
    if (!permissionsGranted) {
      console.error("Location permissions not granted. Stopping tracking.");
      return;
    }

    isTracking = true;
    // console.log("traking started");
    // Start initial poll
    await pollLocation();
  }

  function stopLocationTracking() {
    if (!isTracking) return;

    isTracking = false;

    if (pollingTimeout) {
      clearTimeout(pollingTimeout); // Stop the polling
    }
  }

  return {
    startLocationTracking,
    stopLocationTracking,
    isTracking,
    checkinAlerts,
  };
}
