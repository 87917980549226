import { computed, onUnmounted, watch } from "vue";
import store from "../store";
import { useDeviceId } from "./useDeviceId";
import api from "../utils/api";
// import { getLocalStorageItem, setLocalStorageItem } from "../utils/helpers";

export function usePreferenceStore() {
  const preferences = computed(() => store.state.preferences);

  // Apply the theme based on preference
  function applyTheme(theme) {
    document.documentElement.classList.remove("theme-light", "theme-dark");
    if (theme === "dark") {
      document.documentElement.classList.add("theme-dark");
    } else {
      document.documentElement.classList.add("theme-light");
    }
  }

  // Method to determine the theme based on user preference and system settings
  function applyUserTheme() {
    const userPreference = preferences?.value?.theme || "auto"; // default to "auto" if not set
    if (userPreference === "auto") {
      const systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
      applyTheme(systemDarkMode.matches ? "dark" : "light");

      // Listen for changes in system theme
      const handleSystemThemeChange = (e) => {
        applyTheme(e.matches ? "dark" : "light");
      };
      systemDarkMode.addEventListener("change", handleSystemThemeChange);

      // Cleanup listener on unmount
      onUnmounted(() => {
        systemDarkMode.removeEventListener("change", handleSystemThemeChange);
      });
    } else {
      // If userPreference is "light" or "dark", apply it directly
      applyTheme(userPreference);
    }
  }

  // Fetch user preferences from the API
  async function getPreferences() {
    try {
      const { deviceId } = useDeviceId(); // Get the deviceId
      const prefs = await api.get(`/api/devices/${deviceId.value}`);
      store.mutations.setState("preferences", prefs?.data?.preferences);
    } catch (error) {
      console.error("Error fetching preferences:", error);
      throw error;
    }
  }

  // Set user preferences from the API
  async function setPreferences(data) {
    try {
      const { deviceId } = useDeviceId(); // Get the deviceId
      const prefs = await api.put(`/api/devices/${deviceId.value}`, data);
      store.mutations.setState("preferences", prefs?.data?.preferences);
      return prefs?.data;
    } catch (error) {
      console.error("Error saving preferences:", error);
      throw error;
    }
  }

  // Watch for changes in preferences and apply theme accordingly
  watch(preferences, () => {
    applyUserTheme();
  });

  // Initial setup on component mount
  // onMounted(() => {
  //   //     getPreferences();
  //   applyUserTheme();
  // });

  return {
    preferences,
    applyTheme,
    getPreferences,
    setPreferences,
  };
}
