import { ref, computed } from "vue";

export function useColorAndImage(defaults) {
  const colors = ref([
    { name: "Red", hex: "#f899a2" },
    { name: "Orange", hex: "#FFE0B2" },
    { name: "Yellow", hex: "#FFF9C4" },
    { name: "Green", hex: "#C8E6C9" },
    { name: "Teal", hex: "#B2EBF2" },
    { name: "Blue", hex: "#BBDEFB" },
    { name: "Purple", hex: "#E1BEE7" },
    { name: "Pink", hex: "#fac2d5" },
    { name: "Gray", hex: "#E0E0E0" },
    { name: "White", hex: "#FFFFFF" },
  ]);

  const images = ref([
    { name: "Groceries", url: "/theme/standard/grocery.svg" },
    { name: "Food", url: "/theme/standard/food.svg" },
    { name: "Music", url: "/theme/standard/music.svg" },
    { name: "Recipies", url: "/theme/standard/recipe.svg" },
    { name: "Notes", url: "/theme/standard/notes.svg" },
    { name: "Places", url: "/theme/standard/places.svg" },
    { name: "Travel", url: "/theme/standard/travel.svg" },
    { name: "Movie", url: "/theme/standard/video.svg" },
    { name: "Celebration", url: "/theme/standard/celebration.svg" },
  ]);

  const selectedColor = ref(
    defaults?.color
      ? colors.value.find(
          (c) => c.name.toLowerCase() === defaults?.color.toLowerCase()
        )
      : null
  );
  const selectedImage = ref(defaults?.backgroundImage);

  const selectColor = (color) => {
    selectedColor.value = color;
  };
  const getColor = (clr) =>
    colors.value.find((c) => c?.name?.toLowerCase() === clr?.toLowerCase());
  const selectImage = (image) => {
    selectedImage.value = image;
  };

  const noteStyle = computed(() => ({
    backgroundColor: selectedColor.value ? selectedColor.value?.hex : null,
    color: selectedColor.value || selectedColor.value?.hex ? "#000" : null,
    backgroundImage: selectedImage.value
      ? `url(${selectedImage.value.url})`
      : null,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }));

  const gradStyle = computed(() => ({
    background: `linear-gradient(0deg, ${
      selectedColor.value ? selectedColor.value?.hex : "#fff"
    }, 65%, transparent)`,
  }));

  const footerStyle = computed(() =>
    selectedColor.value
      ? {
          backgroundColor: selectedColor.value
            ? selectedColor.value?.hex
            : null,
        }
      : { backgroundColor: "#fff" }
  );

  return {
    colors,
    images,
    selectedColor,
    selectedImage,
    selectColor,
    selectImage,
    noteStyle,
    gradStyle,
    footerStyle,
    getColor,
  };
}
