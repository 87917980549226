import { ref } from "vue";

const DEVICE_ID_KEY = "deviceId";

export function useDeviceId() {
  const deviceId = ref(null);

  // Function to generate a new unique deviceId
  const generateDeviceId = () => {
    // Generate a secure random string
    const randomBuffer = new Uint8Array(16);
    crypto.getRandomValues(randomBuffer);
    const randomComponent = Array.from(randomBuffer, (b) =>
      b.toString(16).padStart(2, "0")
    ).join("");

    // Combine components
    const rawString = `${randomComponent}`;

    // Encode in Base64URL (safe for URLs and Fastify routes)
    const base64url = btoa(unescape(encodeURIComponent(rawString)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");

    return base64url;
  };

  // Load deviceId from localStorage or generate a new one if not found
  const loadDeviceId = () => {
    const storedDeviceId = localStorage.getItem(DEVICE_ID_KEY);
    if (storedDeviceId) {
      deviceId.value = storedDeviceId;
    } else {
      const newDeviceId = generateDeviceId();
      localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
      deviceId.value = newDeviceId;
    }
  };

  // Optionally allow regenerating the deviceId
  const regenerateDeviceId = () => {
    const newDeviceId = generateDeviceId();
    localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
    deviceId.value = newDeviceId;
  };

  loadDeviceId(); // Load deviceId when the hook is initialized

  return { deviceId, regenerateDeviceId };
}
